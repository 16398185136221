import React from "react";
import { LinkUnderlineComponent } from "./LinkUnderlineComponent.js";
import MarginComponent from "./MarginComponent.js";

export default function HomeNavComponent() {
  return (
    <>
      <MarginComponent small="sm:ml-2" margin="ml-0 flex items-center">
        <LinkUnderlineComponent href="/o-nas">О нас</LinkUnderlineComponent>
      </MarginComponent>
      <MarginComponent small="sm:ml-2" margin="ml-0 flex items-center">
        <LinkUnderlineComponent href="/contakty">
          Контакты
        </LinkUnderlineComponent>
      </MarginComponent>
      <MarginComponent small="sm:ml-2" margin="ml-0 flex items-center">
        <LinkUnderlineComponent href="/nashi-proekty">
          Проекты
        </LinkUnderlineComponent>
      </MarginComponent>
      <MarginComponent small="sm:ml-2" margin="ml-0 flex items-center">
        <LinkUnderlineComponent href="/kurslar">
          Курсы
        </LinkUnderlineComponent>
      </MarginComponent>
    </>
  );
}
