import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Popover } from "@headlessui/react";
import { Link } from "gatsby";
import { MenuIcon, XIcon, GlobeAltIcon } from "@heroicons/react/outline";

import { classNames } from "../../utils/classNamesFn.js";
import { LogoComponent } from "../LogoComponent.js";
import { LinkUnderlineComponent } from "../LinkUnderlineComponent.js";
import MarginComponent from "../MarginComponent.js";
import SocialComponent from "../SocialComponent.js";
import NavComponent from "../NavComponent.js";

export default function Header() {
  const isActive = ({ isCurrent }) => {
    return isCurrent
      ? {
          className:
            "bg-green-500 text-white group flex items-center px-3 py-2 text-sm font-medium rounded-md",
        }
      : {};
  };

  // Получаем данные навигации из Sanity
  const data = useStaticQuery(graphql`
    query HeaderNavQuery {
      development: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "development" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      design: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "design" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      audit: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "audit" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      seo: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "seo" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      improvement: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "improvement" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      support: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "support" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
    }
  `);

  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-white shadow-sm lg:static lg:overflow-y-visible"
          )
        }
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                <div className="flex xl:col-span-2">
                  <div className="flex-shrink-0 flex items-center">
                    <LogoComponent />
                  </div>
                </div>
                  <div className="flex items-center block md:hidden">
                      <a className="text-white bg-blue-600 font-semibold rounded-md uppercase text-xs px-4 py-2" href="tel:+998905715200">
                          Позвонить
                      </a>
                  </div>

                <div className="flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (

                        <MenuIcon
                          className="block h-6 w-6 text-blue-500"
                          aria-hidden="true"
                        />
                    )}
                  </Popover.Button>
                </div>
                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-10">
                  <MarginComponent
                    small="sm:ml-2"
                    margin="ml-0 flex items-center uppercase"
                  >
                    <LinkUnderlineComponent tag="btn" href="/ostavit-zayavku">
                      Заказать сайт
                    </LinkUnderlineComponent>
                  </MarginComponent>

                  {/*<MarginComponent small="sm:ml-8" margin="ml-0">*/}
                  {/*  <div className="text-sm font-semibold">Мы в соцсетях:</div>*/}
                  {/*</MarginComponent>*/}

                  {/*<SocialComponent />*/}

                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                <NavComponent
                  title="Разработка сайта"
                  data={data.development.nodes}
                  isActive={isActive}
                />
                <NavComponent
                  title="Аудит сайта"
                  data={data.audit.nodes}
                  isActive={isActive}
                />
                <NavComponent
                  title="SEO продвижение"
                  data={data.seo.nodes}
                  isActive={isActive}
                />
                <NavComponent
                  title="Дизайн сайта"
                  data={data.design.nodes}
                  isActive={isActive}
                />
                <NavComponent
                  title="Доработка сайта"
                  data={data.improvement.nodes}
                  isActive={isActive}
                />
                <NavComponent
                  title="Сопровождение сайта"
                  data={data.support.nodes}
                  isActive={isActive}
                />
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  );
}
