import React from "react";
import { Link } from "gatsby";

export default function SidebarNavComponent({ title, data, isActive }) {
  return (
    <>
      <div className="text-sm font-bold tracking-wide uppercase mb-2">
        {title}
      </div>
      <div className="pb-8 space-y-1">
        {data.map((item) => (
          <Link
            key={item.id}
            to={`/${item.slug.current}`}
            getProps={isActive}
            className="text-gray-600 hover:text-white hover:bg-blue-600 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
          >
            <span className="truncate">{item.name}</span>
          </Link>
        ))}
      </div>
    </>
  );
}
