import React from "react";
import { Link } from "gatsby";

export function LinkUnderlineComponent({ children, href = "/", tag = "link" }) {
  const cl = "text-sm text-blue-500 hover:text-blue-600 underline pt-10 block";
  const el =
    tag === "a" ? (
      <a href={href} target="_blank" className={cl}>
        {children}
      </a>
    ) : tag === "tel" ? (
      <a href={`tel:${children}`} className="bg-blue-600 text-white px-4 py-2 rounded-md ml-2">
        {children}
      </a>
    ) : (
      <Link to={href} className={`${tag === "btn" ? "bg-blue-600 text-white px-4 py-2 rounded-full" : cl}`}>
        {children}
      </Link>
    );
  return <>{el}</>;
}
