import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Seo } from "./seo.js";
import AsideComponent from "./ui/AsideComponent.js";
import Header from "./ui/nav/header";
import { LinkUnderlineComponent } from "./ui/LinkUnderlineComponent.js";

import { FooterComponent } from "./ui/FooterComponent.js";
import { CenterComponent } from "./ui/CenterComponent.js";
import MarginComponent from "./ui/MarginComponent.js";
import Sidebar from "./ui/nav/sidebar.js";

import Footer from "./ui/nav/footer.js";
import "../styles/slick.css";

export default function Layout({
  children,
  title = false,
  description = false,
  image = false,
  path = false,
  location,
}) {
  const data = useStaticQuery(graphql`
    query GetSiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // const meta = data?.site?.siteMetadata ?? {};

  const loc = location !== "/" && (
    <CenterComponent>
      <LinkUnderlineComponent>Вернуться на главную</LinkUnderlineComponent>
    </CenterComponent>
  );

  return (
    <>
      <Seo title={title} description={description} image={image} path={path} />
      <div className="relative min-h-screen bg-gray-100">
        {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
        <Header />
        {/* NacComponent Main AsideCOmponent */}

        <div className="py-10">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="hidden lg:block lg:col-span-3 xl:col-span-3">
              <Sidebar />
            </div>
            <main className="lg:col-span-9 xl:col-span-9">
              {loc}
              {children}
              <MarginComponent small="sm:mt-8" margin="mt-4">
                {loc}
              </MarginComponent>
            </main>
          </div>
        </div>

        <FooterComponent />
      </div>
    </>
  );
}
