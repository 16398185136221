import React from "react";
import { Link } from "gatsby";

export default function NavComponent({ title, data, isActive }) {
  return (
    <>
      <div className="text-sm font-medium tracking-wide text-gray-500 uppercase mb-2">
        {title}
      </div>
      {data.map((item) => (
        <Link
          key={item.name}
          to={`/${item.slug.current}`}
          getProps={isActive}
          className="text-gray-600 hover:text-white hover:bg-blue-600 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
        >
          {item.name}
        </Link>
      ))}
    </>
  );
}
