import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SidebarNavComponent from "../SidebarNavComponent";

export default function Sidebar() {
  // Если состояние isCurrent равняется true - сделать активным
  const isActive = ({ isCurrent }) => {
    return isCurrent
      ? {
          className:
            "bg-green-500 text-white group flex items-center px-3 py-2 text-sm font-medium rounded-md",
        }
      : {};
  };

  // Получаем данные навигации из Sanity
  const data = useStaticQuery(graphql`
    query NavQuery {
      development: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "development" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      design: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "design" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      audit: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "audit" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      seo: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "seo" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      improvement: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "improvement" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
      support: allSanityNavigation(
        filter: { cat: { elemMatch: { name: { eq: "support" } } } }
        sort: { fields: _createdAt, order: ASC }
      ) {
        nodes {
          id
          name
          slug {
            current
          }
          _createdAt
        }
      }
    }
  `);

  return (
    <nav aria-label="Sidebar" className="sticky top-4 ">
      <SidebarNavComponent
        title="Разработка сайта"
        data={data.development.nodes}
        isActive={isActive}
      />
      <SidebarNavComponent
        title="Аудит сайта"
        data={data.audit.nodes}
        isActive={isActive}
      />
      <SidebarNavComponent
        title="SEO продвижение"
        data={data.seo.nodes}
        isActive={isActive}
      />
      <SidebarNavComponent
        title="Дизайн сайта"
        data={data.design.nodes}
        isActive={isActive}
      />
      <SidebarNavComponent
        title="Доработка сайта"
        data={data.improvement.nodes}
        isActive={isActive}
      />
      <SidebarNavComponent
        title="Сопровождение сайта"
        data={data.support.nodes}
        isActive={isActive}
      />
    </nav>
  );
}
