import React from "react";

export function TitleComponent({ children, as = "h2" }) {
  const element =
    as === "h3" ? (
      <h3 className="text-base leading-6 text-gray-900 pt-20 pb-10 font-bold">
        {children}
      </h3>
    ) : (
      <h2 className="text-2xl uppercase leading-6 text-center text-gray-900 pt-20 pb-10 font-bold">
        {children}
      </h2>
    );
  return <>{element}</>;
}
