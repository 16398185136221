import React from "react";
import { Link } from "gatsby";

export function LogoComponent() {
  return (
    <Link to="/" className="sm:py-5 py-3 sm:px-3">
      <svg className="sm:w-10 w-7" width="47" height="32" viewBox="0 0 47 32">
        <path
          fill="#4e4e4e"
          d="M7.352 4.559l-1.195 4.426-6.172 23.017h9.501l6.184-23.079 12.166-0.080 2.369-8.844h-1.505l-15.41 0.106c-2.614 0.014-5.276 2.019-5.939 4.454z"
        ></path>
        <path
          fill="#f26722"
          d="M39.936 27.441l1.195-4.426 6.172-23.015h-9.501l-6.184 23.077-12.165 0.080-2.371 8.846h1.151l15.766-0.104c2.614-0.018 5.286-2.023 5.937-4.458z"
        ></path>
      </svg>
    </Link>
  );
}
