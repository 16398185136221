import React from "react";
import { Link } from "gatsby";
import SocialComponent from "./SocialComponent";
import HomeNavComponent from "./HomeNavComponent";

export function FooterComponent() {
  const date = new Date().getFullYear();
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-7">
        <div className="flex flex-wrap justify-center space-x-6 md:order-2">
          {/*<SocialComponent />*/}
          <HomeNavComponent />
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-sm text-gray-500">
            &copy; {date} JAHONDEV.UZ
          </p>
        </div>
      </div>
    </footer>
  );
}
